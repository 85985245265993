import React, { useState,  useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
// import { renderToStaticMarkup } from 'react-dom/server';
// import imgBlob from '../../images/blob.svg'


const Hero = () => {
    const intl = useIntl()

    // Retrieve data from /content/quotations
    const quotationsQuery = useStaticQuery(graphql`
    query Quotations {
        allMarkdownRemark(filter: {collection: {eq: "quotations"}}) {
          nodes {
            frontmatter {
              author
              rank_it
              rank_en
              text_it
              text_en
            }
          }
        }
      }`)
    const quotations = quotationsQuery.allMarkdownRemark.nodes

    // Loop random quotation (different from last 5)
    const [currentQuotation, setCurrentQuotation] = useState(quotations[Math.floor(Math.random() * quotations.length)]);
    
    const lastUsedIndex = []
    useEffect(() => {
        const interval = setInterval(() => {
            let index = 0
            for (let i = 0; i < quotations.length && lastUsedIndex.includes(index) ; i++) {
                index = Math.floor(Math.random() * quotations.length)
            }

            lastUsedIndex.push(index)
            if(lastUsedIndex.length >= quotations.length)
                while (lastUsedIndex.length) { lastUsedIndex.pop(); }
            
            setCurrentQuotation(quotations[index]);
            
        }, 4500);
        return () => clearInterval(interval);
    }, []);
    

    return (
        <div className="hero-container mx-auto max-w-screen-xl block  h- relative" > 
            <div className="absolute inset-0 z-0 -mx-5 md:mx-auto">
                <video className="object-cover w-full h-full opacity-50 " autoPlay muted loop playsInline >
                    <source src="https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fhero_video.mp4?alt=media&token=963c2df7-b61c-4b9d-9114-43507fd7e8cc" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                       
            </div>
            <div className="flex h-full  max-w-screen-xl relative z-10 text-white">
                <div className="m-auto md:ml-20 md:mb-20 md:mt-auto md:my-auto text-center md:text-left bg-gray-900 bg-opacity-50 p-5">
                    <q className="max-w-2xl font-heading text-3xl md:text-5xl block">
                       {currentQuotation.frontmatter[`text_${intl.locale}`]}
                    </q>
                    <span className="italic text-sm md:text-lg ml-2 mt-4 block">&mdash; <span className="font-bold">{currentQuotation.frontmatter.author}</span>, {currentQuotation.frontmatter[`rank_${intl.locale}`]}</span>
                </div>
            </div>
        </div>
    )
}

export default Hero;