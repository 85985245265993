import React from "react"
// import { SRLWrapper } from 'simple-react-lightbox'
// import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "../components/Layout.js"
import Hero from "./components/Hero.js"
import UpcomingEvents from "./components/UpcomingEvents.js"
import EventsShowcase from "./components/EventsShowcase.js"
import Information from "./components/Information.js"
import Resources from "./components/Resources.js"
import Partners from "./components/Partners.js"
import Footer from "./components/Footer.js"


export default () => {


  return (
    <Layout>
      <main id="home" className="">
        
        <div id="hero" className="w-full px-5 bg-gray-900">
          <Hero />
        </div>

        <div id="upcoming-events" className="w-full px-5 bg-blue-700">
          <UpcomingEvents />
        </div>

        <div id="events-showcase" className="w-full px-5 bg-gray-200">
            <EventsShowcase />
        </div>

        <div id="information" className="w-full px-5">
          <Information />
        </div>

        <div id="resources" className="w-full px-5 bg-gray-200">
          <Resources />
        </div>

        <div id="partners" className="w-full px-5">
          <Partners />
        </div>

        <div id="footer" className="w-full px-5  bg-gray-900">
          <Footer />
        </div>
        
      </main>
    </Layout> 
  )
}
