import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"

const EventsShowcase = () => {
    const [activeTab, setActiveTab] = useState('exams');
    const eventPanelRef = React.createRef();
    const intl = useIntl()

    const switchTab = (slug) => {
        const panel = eventPanelRef.current
        panel.scrollTop = 80
        setActiveTab(slug)
    };


    // Retrieve data from /content/quotations
    const activitiesQuery = useStaticQuery(graphql`
    query Activities {
        allHomeYaml(filter: {section: {eq: "activities"}}) {
          nodes {
            exams {
              content {
                it
                en
              }
              title {
                it
                en
              }
            }
            camps {
              content {
                it
                en
              }
              title {
                it
                en
              }
            }
            seminars {
              content {
                it
                en
              }
              title {
                it
                en
              }
            }
            tournaments {
              content {
                it
                en
              }
              title {
                it
                en
              }
            }
          }
        }
      }
    `)

    const activities = activitiesQuery.allHomeYaml.nodes[0]
    
    const eventTypes = [
        {
            slug:'exams',
            label: activities.exams.title[intl.locale],
            content: activities.exams.content[intl.locale],
            images:[
                {alt: "Hwal Moo Do exam",  url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fexams_1_.jpg?alt=media&token=4be96682-d0c5-45d7-afe3-7fbdce9908ee"},
                {alt: "Hwal Moo Do exam",  url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fexams_2_.jpg?alt=media&token=8a7929db-aeab-4716-9f75-7cd90ff5f00e"}
            ],
            videos:[
                {alt:"", url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fvideo_exams.mp4?alt=media&token=2eda2a7c-4066-490a-ac9f-8e987f879192#t=4"}
            ]
        },
        {
            slug:'tournaments', 
            label: activities.tournaments.title[intl.locale],
            content: activities.tournaments.content[intl.locale],
            images:[
                {alt: "Hwal Moo Do tournament",  url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Ftournaments_1_.jpg?alt=media&token=ef48152e-5536-47b0-a522-d13362d47720"},
                {alt: "Hwal Moo Do tournament",  url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Ftournaments_2_.jpg?alt=media&token=88c5cebe-6713-4b76-a0ba-c07c7ed1cf6e"}
            ],
            videos:[
                {alt:"", url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fvideo_tournaments.mp4?alt=media&token=5af568eb-918f-4a7e-ad2f-fee97f231515#t=1.4"}
            ]
        },
        {
            slug:'camps',
            label: activities.camps.title[intl.locale],
            content: activities.camps.content[intl.locale],
            images:[
                {alt: "Hwal Moo Do camp",  url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fcamp_traditional.jpg?alt=media&token=b7884d0a-3a51-45a6-852c-442968f90575"},
            ],
            videos:[
                {alt:"", url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fvideo_camp_kids.mp4?alt=media&token=c66afe40-caa7-4670-b97e-a9f8b28bd96c"}
            ]
        },
        {
            slug:'seminars',
            label: activities.seminars.title[intl.locale],
            content: activities.seminars.content[intl.locale],
            images:[
                {alt: "Hwal Moo Do seminar",  url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fseminars_1_.jpg?alt=media&token=e4c5aa3e-31c2-4013-aee9-79d7dbc2abbd"},
            ],
            videos:[
                {alt:"", url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fvideo_seminar.mp4?alt=media&token=7d49f833-b925-4f0a-b89f-1a7aeaad8c66#t=1"}
            ]
        }
    ]


    return (
        <div ref={eventPanelRef} className="mx-auto py-16 max-w-screen-xl ">
            {/* tabs */}
            <nav className="mb-10 bg-gray-200  ">
                
                <div className="flex md:-mb-px  md:justify-center scrolling-touch overflow-x-scroll md:overflow-x-auto max-w-full ">
                {eventTypes.map(eventType => {
                    const isActive = eventType.slug === activeTab
                    return(
                        <button  key={`tab-${eventType.slug}`} className={`cursor-pointer no-underline text-grey-dark border-b-2 ${isActive ? 'border-gray-900' :'border-transparent'} font-heading font-bold uppercase text-3xl py-1 mx-3 md:mx-6`}  onClick={() => switchTab(eventType.slug)} >
                            {eventType.label}
                        </button>
                    )
                })}
                </div>

                <div className="md:hidden w-20 mx-auto pb-2 text-center">
                    <svg className="inline-block fill-current w-4 h-4 mr-2" 
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M3.828 9l6.071-6.071-1.414-1.414L0 10l.707.707 7.778 7.778 1.414-1.414L3.828 11H20V9H3.828z"/>
                    </svg>
                    
                    <svg className="inline-block fill-current w-4 h-4 ml-2" 
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"/>
                    </svg>
                </div>
         
            </nav>

            {/* exams */}
            <div className="activities-panel relative flex flex-col min-w-0 break-words w-full overflow-x-hidden">
                <div  className={` ${activeTab === 'exams' ? "block" : "hidden"} `} id="link1">
                    <div className="grid grid-cols-12 gap-4 flex-wrap-reverse">
                        <div className="col-span-12 md:col-start-1 md:col-end-7"
                            dangerouslySetInnerHTML={{
                                __html: eventTypes.find(e => e.slug === 'exams').content}}>
                        </div>
                        <div className="col-span-12 md:col-start-8 md:col-end-13">
                            <img className="rounded mb-10" src={eventTypes.find((e) => e.slug === 'exams').images[1].url} alt="Esami Hwal Moo Do" />
                            <video className="rounded"  controls>
                                <source src={eventTypes.find((e) => e.slug === 'exams').videos[0].url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                
                {/* tournaments */}
                <div  className={` ${activeTab === 'tournaments' ? "block" : "hidden"} `} id="link2">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-start-1 md:col-end-6 ">
                            <img className="rounded  mb-10" src={eventTypes.find((e) => e.slug === 'tournaments').images[1].url} alt="Gare Hwal Moo Do" />  
                            <video className="rounded mb-10"  controls>
                                <source src={eventTypes.find((e) => e.slug === 'tournaments').videos[0].url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <div className="col-span-12 md:col-start-7 md:col-end-13"
                           dangerouslySetInnerHTML={{
                            __html: eventTypes.find(e => e.slug === 'tournaments').content}}>
                        </div>
                    </div>
                </div>
                
                {/* camps */}
                <div  className={` ${activeTab === 'camps' ? "block" : "hidden"} `} id="link3">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-start-1 md:col-end-7"
                         dangerouslySetInnerHTML={{
                            __html: eventTypes.find(e => e.slug === 'camps').content}}>
                        </div>
                        <div className="col-span-12 md:col-start-8 md:col-end-13">
                            <img className="rounded  mb-10" src={eventTypes.find((e) => e.slug === 'camps').images[0].url} alt="Gare Hwal Moo Do" />
                            <video className="rounded mb-10"  controls>
                                <source src={eventTypes.find((e) => e.slug === 'camps').videos[0].url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                
                {/* seminars */}
                <div  className={` ${activeTab === 'seminars' ? "block" : "hidden"} `} id="link4">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-start-1 md:col-end-6">
                            <img className="rounded  mb-10" src={eventTypes.find((e) => e.slug === 'seminars').images[0].url} alt="Gare Hwal Moo Do" />
                            <video className="rounded mb-10"  controls>
                                <source src={eventTypes.find((e) => e.slug === 'seminars').videos[0].url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <div className="col-span-12 md:col-start-7 md:col-end-13"
                         dangerouslySetInnerHTML={{
                            __html: eventTypes.find(e => e.slug === 'camps').content}}>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    )
}

export default EventsShowcase;