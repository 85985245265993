import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import HmdIcon from  "../../images/hmd_stemma.svg"
import KdIcon from  "../../images/kd_stemma.svg"

const Resources = () => {
    const intl = useIntl()

    const resources = [
        {
            key:'teoria-hmd',
            image: HmdIcon,
            type: "document",
            title: "Teoria Hwal Moo Do",
            description:"Programma di teoria HMD in formato pdf.",
            buttonLabel: intl.formatMessage({ id: "open" }),
            url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2FTeoria%20Hwal%20Moo%20Do%202018.pdf?alt=media&token=a2ce04c2-6ce7-41c3-b2de-75922836fa43"
        },
        {
            key:'teoria-kd',
            image: KdIcon,
            type: "document",
            title: "Teoria Korean Dragon",
            description:"Programma di teoria KD in formato pdf.",
            buttonLabel:intl.formatMessage({ id: "open" }),
            url:"https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2FTeoria%20Korea%20Dragon%202018.pdf?alt=media&token=5e9f67ee-ba50-43e8-891e-66be4c569418"
        },
    ]   

    
    const openResource = (url) => {
        window.open(url, "_blank") //to open new page
        
    }

    return (
        <div className="mx-auto max-w-screen-xl py-20">
            <h2 className="font-heading text-3xl text-center pb-5">{intl.formatMessage({ id: "resourcesForTheStudents" })}</h2>
            <div className={`flex ${resources.length < 3 ? 'justify-center':''} flex-wrap`}>
                {resources.map((resource, index) => {
                    return(
                    <div key={`resource-${index}`} className=" max-w-sm">
                        <div className="rounded overflow-hidden bg-white shadow m-2">
                        <div className="w-full bg-gray-700">
                            <img alt="" src={resource.image} className="w-32 mx-auto" />
                        </div>
                        <div className="px-4 py-4">
                            <div className="font-bold text-md mb-2">{resource.title}</div>
                            <p className="text-gray-700  text-sm">{resource.description}</p>
                        </div>
                        <div className="px-4 pb-4">
                            <button
                             onClick={()=>openResource(resource.url)}   
                             className="bg-transparent hover:bg-blue-500 text-sm text-blue-700 font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded">
                            {/* <svg className="fill-current w-4 h-4 mr-3 mb-1 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg> */}
                            <svg className="fill-current w-4 h-4 mr-3 mb-1 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15 3H7a7 7 0 1 0 0 14h8v-2H7A5 5 0 0 1 7 5h8a3 3 0 0 1 0 6H7a1 1 0 0 1 0-2h8V7H7a3 3 0 1 0 0 6h8a5 5 0 0 0 0-10z"/></svg>
                            {resource.buttonLabel}
                            </button>
                        </div>
                        </div>
                    </div>
                    )})
                }
               
            </div>
        </div>
      
    )
}

export default Resources;